<template>
    <v-content>
        <div v-if="! moduleReport">
            <v-breadcrumbs :items="items" large></v-breadcrumbs>
            <v-row>

                <v-col cols="2"
                       v-for="title in titles"
                       v-bind:key="title.link"
                       class="viewCard">
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <router-link :to="title.link">

                                <HoverCard :cardData="title"></HoverCard>

                            </router-link>
                        </template>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>
    </v-content>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'
    export default {
        name: "Dashboard",
        watch: {},
        components: {
            HoverCard
        },
        computed: {
            titles() {
                return [
                    {
                        name: this.$t('modules_name.nrb'),
                        link: "/reportModules/reportNrb",
                        icon: "mdi-bank"
                    },



                ]
            },
            items() {
                return [
                    {
                        text: this.$t('report'),
                        disabled: false,
                        to: "/reportModules",
                        exact: true
                    },
                    {
                        text: this.$t('income_generation_report'),
                        disabled: true,
                    }



                ]
            }
        },
        data() {
            return {

                moduleReport: false,
                moduleName: '',
                componentName: ''
            }
        },
        mounted() {
            this.formData()
        },
        methods: {
            formData() {

            },

            goBackToReportSelection() {
                this.componentName = 'selectReportTypeComponent'
            },

            goBackToReportDashboard() {
                this.moduleReport = false
            },

            goToReport(type) {
                this.componentName = type
                console.log('this,moduleName: ', this.moduleName)
            },

            goToModuleReport(moduleName) {
                this.moduleName = moduleName
                this.componentName = 'selectReportTypeComponent'
                this.moduleReport = true
            }
        }
    };
</script>

<style lang="scss" scoped>
    a {
        text-decoration: none;
    }
</style>